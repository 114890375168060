import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from './services/users.service';
import { RequestCensorComponent } from './shared_components/modals/request-censor/request-censor.component';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ONC';
  nameClass = {home: false, censdata: false}
  constructor(
    public usersService:UsersService,
    public dialog: MatDialog,
    private router: Router,
    public route: ActivatedRoute) {
      router.events.subscribe(() => {
        this.nameClass.home = router.url === '/'
        this.nameClass.censdata = router.url === '/censdata'
      })
    }

  requestCensor() {
    this.router.navigateByUrl('/solicitud-censador')
    // const dialogRef = this.dialog.open( RequestCensorComponent , {
    //   width: '50vw',
    //   height: '90vh'
    // });
  }
}
