import { UsersService } from './users.service';
import { Injectable } from '@angular/core';
import * as firebase from "firebase/app";
import 'firebase/auth'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth: any;
  public user: firebase.User;
  constructor(private usersService:UsersService) {
    this.auth = firebase.auth();
    this.subscribe();
  }
  private subscribe() {
    this.auth.onAuthStateChanged(_user => {
      this.usersService.userFirebase$.next(_user); this.user = _user;
    });
  }

  signInWithEmail(credentials) {
    return this.auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signInWithOAuthBrowserMode(provider: string): Promise<any> {
    var promise = new Promise<any>((resolve, reject) => {
      this.auth
        .signInWithPopup(this.resolveProvider(provider))
        .then(user => {
          resolve(user);
        })
        .catch(e => {
          console.error(`${provider} Login Failure:`, e);
          reject(e);
        });
    });
    return promise;
  }

  private resolveProvider(provider: string) {
    switch (provider) {
      case "Google":
        return new firebase.auth.GoogleAuthProvider();
      case "Facebook":
        return new firebase.auth.FacebookAuthProvider();
      case "Twitter":
        return new firebase.auth.TwitterAuthProvider();
    }
  }

  async signOut(): Promise<void> {
    return await this.auth.signOut();
  }

  async sendRestorePassEmail(email){
    const updateUser =async (id)=> {
      await this.usersService.updateUser(id,
      {
        restorePass:{
          created_at:new Date(),
          sendedLink:true
        }
      })
    }
    const user:any = await this.usersService.fireService.getUserByEmail(email)
    const query = (
      user[0] && user[0].restorePass
      && (user[0].restorePass.created_at))

    if(query){
      const dateSendedEmail:Date = user[0].restorePass.created_at.toDate()
      if(dateSendedEmail.getDate() + 1 < new Date().getDate() ){
        await this.auth.sendPasswordResetEmail(email)
        updateUser(user[0].id)
      }else{
        return false;
      }
    }else{
      await this.auth.sendPasswordResetEmail(email)
      updateUser(user[0].id)
    }
  }

  async fetchSignInMethodsForEmail(email){
    const provider = await this.auth.fetchSignInMethodsForEmail(email)
    return provider
  }
}
