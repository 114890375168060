import { FireService } from './fire.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators'
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

const ref = '/users'
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  userFirebase$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.user || 'null'))
  user$ = new BehaviorSubject(JSON.parse(localStorage.user || 'null'))
  user = JSON.parse(localStorage.user || 'null')
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public fireService: FireService) {
    this.subcription()
  }

  subcription() {
    this.userFirebase$.subscribe(async (_userAuth) => {
      if (_userAuth) {
        await this.fireService.on(_userAuth.uid || _userAuth.id, `/users/`, this.user$);
        this.user$.subscribe((_user) => {
          if (_user)
            this.ngZone.run(() => {
              const beforeUser = !!this.user
              this.user = this.decorateAttributes(_user);
              localStorage.user = JSON.stringify(this.user)
              if (!beforeUser && _user) {
                this.router.navigate(['/dashboard']);
              }
            })
        })
      } else {
        delete localStorage.user
        this.user = null
      }
    })
  }

  async getAllUsers(filters?) {
    const arrFilters: any = Object.values(filters)
    if (!this.user.roles.admin) {
      arrFilters.push(['created_by', '==', this.user.id])
    }
    let items$: Subject<any> = new Subject();
    await this.fireService.getCollectionOn(ref, items$, arrFilters || null, 'email')
    return items$
  }


  async getAllUsersNonSubject() {
    const species = await this.fireService.getCollection('/users', [], 'name')
    return species;
  }

  async updateUser(id: string, data: any) {
    const ref = `users`
    try {
      await this.fireService.update(ref, this.decorateBeforUpdate(data), id ? id : this.user.id)
      return 'ok'
    } catch (error) {
      return error
    }
  }

  async getUser(id: string) {
    const ref = `users`
    try {
      const user = await this.fireService.get(ref, id)
      return user
    } catch (error) {
      return error
    }
  }

  async toogleStatus(userID) {
    const request = this.fireService.onCallFunctions('usersManagement', { id: userID, action: 'toogleStatus' })
  }

  async setStatus(userID, status) {
    const request = await this.fireService.onCallFunctions('usersManagement', { id: userID, disabledStatus: status, action: 'setStatus' })
  }

  decorateAttributes(item: any) {
    if (item.cotos) {
      const coto: any = Object.values(item.cotos)[0]
      item.$coto_id = coto.id
      item.$place = coto.place
      item.$coto_ccaa = coto.ccaa
    }
    if (item.captures) {
      item.$captures = {}
      item.captures.map(capture => {
        item.$captures[capture.year] = item.$captures[capture.year] || {}
        item.$captures[capture.year][capture.name] = capture
      })
    }
    return item
  }

  decorateBeforUpdate(item) {
    const data = { ...item }
    console.log("data--->", data.$captures)
    if (data.$captures) {
      console.log("hoolaaa")
      const years = Object.values(data.$captures)
      data.captures = years.map(year => Object.values(year))
      data.captures = data.captures.flat()
      delete data.$captures
      delete data.$coto_id
      delete data.$coto_ccaa
      delete data.$place
    }
    console.log("data", data)
    return data
  }
}
