import { AlertComponent } from './../../../shared_components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signIn:FormGroup = null;
  error = null
  @Output() eventOut = new EventEmitter();
  constructor(
    private formBuilder:FormBuilder,
    private authService:AuthService,
    private dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.signIn = this.formBuilder.group({
      email:['',[Validators.email,Validators.required]],
      password:['',[Validators.minLength(6),Validators.required]]
    })
  }

  async onSubmit(){
    if(this.restorePass === true){
      await this.sendEmailRestorePassword(this.signIn.value['email'])
      const dialogRef = this.dialog.open(AlertComponent, {
        width: '500px',
        data: {
          type:'alert',
          title:'Restablecer contraseña',
          message:'Se ha enviado un email al correo introducido para restablecer su contraseña'
        }
      });
      this.cancel()
    }else if (this.signIn.valid === true) {
      try {
        await this.authService.signInWithEmail(this.signIn.value)
      } catch (error) {
        this.error = error
      }
    }
  }
  restorePass = false;
  async sendEmailRestorePassword(email){
    await this.authService.sendRestorePassEmail(email)
  }


  cancel(){
    this.eventOut.emit({cancel:true})
  }
/*   async signInGoogle(){
    await this.authService.signInWithOAuthBrowserMode('Google')
  } */
}

