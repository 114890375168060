<ng-container *ngIf="this.usersService.user ;else notUser">
  <div class="user-loggued">
    <button mat-button [matMenuTriggerFor]="appMenu" (click)="toggle('user-menu')">{{user.email}}</button>
    <div class="avatar" [routerLink]="['/dashboard/profile']">
      <img [src]="user.photoURL || '/assets/icons/users.svg'">
    </div>
    <mat-menu #appMenu="matMenu">
        <button mat-menu-item routerLink="/dashboard" >
          <img class="dashboard" src="/assets/icons/dashboard.svg">
          Dashboard</button>
        <button mat-menu-item (click)="signOut()">
          <img src="/assets/icons/exit.svg">
          Salir
        </button>
    </mat-menu>
  </div>
</ng-container>
<ng-template #notUser>
  <div class="user-notLoggued">
    <div class="sign sign-in">
      <button mat-raised-button (click)="toggle('sign-in')">
          <img src="/assets/icons/key.svg">
          Acceso usuarios
      </button>
      <div *ngIf="signInOut === true" class="popup sign-in-container">
          <span class="pico"></span>
          <app-sign-in (eventOut)="handleEvent($event)"></app-sign-in>
      </div>
    </div>
  </div>
</ng-template>
