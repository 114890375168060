
<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="img-cont">
      <img src="/assets/icons/alert-icon.svg">
    </div>
    <mat-card-title>{{data.title}}</mat-card-title>
    <mat-card-subtitle>{{data.subTitle || ''}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>{{data.message || ''}}</p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">Aceptar</button>
    </mat-card-actions>
</mat-card>
