<mat-toolbar [ngClass]="nameClass" class="mat-elevation-z0 jc-space-between">
  <!-- <button class="arrow-container" [ngClass]="{'menu-close': false}" mat-raised-button (click)="sidenav.toggle()">
      <img [ngClass]="{'rotate-180': sidenav.opened === true}" src="/assets/icons/arrow-r-color.svg">
    </button> -->

  <div [routerLink]="['']" class="logo-container">
    <img class="logo" src="/assets/icons/oc-logo-color.svg">
    <p>Observatorio Cinegético</p>
  </div>

  <span class="profile-top d-flex ai-center">
    <button [ngClass]="nameClass" *ngIf="!this.usersService.user?.roles.censor" [routerLink]="['/solicitud-censador']"
      mat-button>Hazte censador</button>
    <app-user-box></app-user-box>
    <button title="Preguntas frecuentes" class="faq-btn" [routerLink]="['/faq']">?</button>
  </span>
</mat-toolbar>
<router-outlet></router-outlet>