import { RolesPermissionsGuard } from './guards/roles-permissions.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./page/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [RolesPermissionsGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./page/sign-in-sign-up/sign-in-sign-up.module').then(m => m.SignInSignUpModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./page/more-info/more-info.module').then(m => m.MoreInfoModule)
  },
  {
    path: 'censdata',
    loadChildren: () => import('./page/info-censdata/info-censdata.module').then(m => m.InfoCensdataModule)
  },
  {
    path: 'politica-privacidad',
    loadChildren: () => import('./page/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./page/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'solicitud-censador',
    loadChildren: () => import('./shared_components/modals/request-censor/request-censor.module').then(m => m.RequestCensorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
