import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';




@NgModule({
  declarations: [AlertComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule
  ],
  entryComponents:[AlertComponent]
})
export class AlertModule { }
