import { RouterModule } from '@angular/router';
import { UserBoxComponent } from './user-box.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatListModule, MatMenuModule } from '@angular/material';
import { SignInSignUpModule } from 'src/app/page/sign-in-sign-up/sign-in-sign-up.module';



@NgModule({
  declarations: [UserBoxComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    SignInSignUpModule,
    MatMenuModule,
    RouterModule
  ],
  exports:[UserBoxComponent]
})
export class UserBoxModule { }
