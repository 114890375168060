<div class="container-1">
  <div class="sign-in" *ngIf="signIn">
    <mat-card>
      <mat-card-content>
          <form [formGroup]="signIn" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="this.signIn.controls.email.invalid">Email incorrecto</mat-error>
              </mat-form-field>

              <ng-container *ngIf="restorePass === false">
                <mat-form-field>
                  <input matInput #pass type="password" placeholder="Password" formControlName="password" required>
                  <span [ngClass]="{'show-pass': pass.type === 'text'}" matSuffix (click)="pass.type = pass.type === 'password' ? 'text' : 'password' ">Mostar</span>
                </mat-form-field>

                <mat-error *ngIf="this.error?.code === 'auth/wrong-password'">*Contraseña incorrecta</mat-error>
                <mat-error *ngIf="this.error?.code === 'auth/user-not-found'">El usuario no existe</mat-error>
              </ng-container>


              <div class="a-c-btn">
                  <button mat-button type="submit">Aceptar</button>
                  <button class="c-btn" mat-button (click)="cancel()">Cancelar</button>
              </div>
          </form>
      </mat-card-content>
      <mat-card-actions>
          <!-- <button (click)="signInGoogle()" mat-button class="w-100 g-btn">GOOGLE</button> -->
          <button
            *ngIf="restorePass === false"
            mat-button (click)="this.restorePass = true">Restablecer contraseña
          </button>
      </mat-card-actions>

    </mat-card>
  </div>
</div>
