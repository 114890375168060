export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyAB7L4g_855R7rMmJCk9QNfAutGRyu3aww",
    authDomain: "caza-app.firebaseapp.com",
    databaseURL: "https://caza-app.firebaseio.com",
    projectId: "caza-app",
    storageBucket: "caza-app.appspot.com",
    messagingSenderId: "653402303521",
    appId: "1:653402303521:web:1d86f94802bffc0b"
  },
  mapbox: {
    secret: 'pk.eyJ1Ijoic2FudGlhZ29tcnYiLCJhIjoiY2p2MmZubGllMDlnOTN5cGY2dTJndTRtYSJ9.kIIn_4BMCRLmhJchC-SDIg'
  },
  functionsHost: 'http://localhost:5001/caza-app/us-central1'
};
