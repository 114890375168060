import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

@Injectable({
  providedIn: 'root'
})
export class FireService {

  geo: any
  cache: {
    list?: Array<any>
  } = {}
  subscription: any

  constructor() {

  }

  private db(service) {
    return firebase.firestore().collection(service)
  }

  private group(service) {
    return firebase.firestore().collectionGroup(service)
  }

  on(id, service, item$) {
    return this.db(service).doc(id).onSnapshot((doc) => {
      item$.next({ id: doc.id, ...doc.data() })
    })
  }

  private queryConditions(aux, options?: any) {
    let query = aux
    const startAfter = (options || {}).startAfter
    const wheres = (options || {}).wheres || []
    const limit = 10000 || (options || {}).limit || 3000
    const orderBy = (options || {}).orderBy || [['created_at', 'desc']]

    if (options.location) {
      const center = this.geo.point(...options.location);
      const radius = options.radius;
      const field = 'location';
      query = query.within(center, radius, field)
    } else if (orderBy && orderBy.length) {
      orderBy.map(i => query = query.orderBy(...i))
      query = query.limit(limit)
    }

    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    wheres.map(where => (query = query.where(...where)))
    return query
  }

  private unsubscribe() {
    try {
      this.subscription && this.subscription.subscription && this.subscription.unsubscribe()
    } catch (error) { }
    try { this.subscription && this.subscription() } catch (error) { }
  }

  private clean(items, _items) {
    _items.push(...items);
    _items.forEach(i => {
      const index = _items.findIndex(t => t.id === i.id);
      Object.assign(_items[index], i);
    });
    _items = _items.filter(
      (i, index, self) => index === self.findIndex(t => t.id === i.id)
    );
    return _items;
  }

  getList(service, items$, options?: any, _items?: any) {
    let query;
    this.unsubscribe();
    if (options.location) {
      query = this.geo.collection(service, ref => {
        return options.where && options.where.length
          ? ref.where(...options.where[0])
          : ref;
      });
      this.subscription = this.queryConditions(query, options).subscribe(
        items => {
          if (_items) _items = this.clean(items, _items);
          items$.next({
            items: _items || items,
            first: (_items || items)[0],
            reset: true
          });
        }
      );
    } else {
      this.subscription = this.queryConditions(
        this.db(service),
        options
      ).onSnapshot(snap => {
        let items = [],
          last,
          first;
        if (snap.docs) {
          items = snap.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          last = snap.docs[snap.docs.length - 1];
          first = snap.docs[0];
        }
        if (_items) _items = this.clean(items, _items);
        items$.next({ items: _items || items, first, last, reset: true });
      });
    }
  }

  get(service, id: string) {
    return this.db(service).doc(id).get()
      .then(doc => {
        if (doc.data()) {
          const data = doc.data()
          data.id = doc.id
          return data
        } else {
          return null;
        }
      })
  }

  find(id: string, items: Array<any>) {
    const item = items.find(a => a.id === id)
    if (item) {
      return new Promise((resolve, reject) => resolve(item))
    } else {
      return new Promise((resolve, reject) => reject('Not Found'))
    }
  }

  async findItem(index, id, items: Array<any>) {
    let found
    if (items && items.length) {
      found = items.find(a => a.id === id)
    }
    if (found) {

      return this.find(id, items)
    } else {
      return this.get(index, id)
    }
  }

  getID(pathCollection: string): string {
    return this.db(pathCollection).doc().id
  }

  create(pathCollection: string, data: any, id?: string) {
    const docId = id ? id : this.getID(pathCollection)
    const created_at = firebase.firestore.FieldValue.serverTimestamp();
    return this.db(pathCollection).doc(docId).set({ ...data, created_at });
  }
  update(pathCollection: string, data: any, id: string) {
    return this.db(pathCollection).doc(id).update(data);
  }

  delete(pathCollection: string, id: string) {
    return this.db(pathCollection).doc(id).delete();
  }

  saveFile(refID: string, pathCollection: string, fileBlod: File): Promise<any> {
    return new Promise((resolve, reject) => {
      let ref = firebase.storage().ref(pathCollection).child(refID + Date.now());
      ref.put(fileBlod).then((res) => {
        resolve(res.ref.getDownloadURL());
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getCollection(ref, filters: Array<Array<any>>, orderBy?: string) {
    let query: any = this.db(ref)
    if (filters.length > 0) {
      filters.forEach((_filter) => {
        query = query.where(_filter[0], _filter[1], _filter[2])
      })
    } else {
      query = this.db(ref)
    }
    return query.limit(10).orderBy(orderBy || 'created_at', 'desc').get();
  }

  getCollectionOn(ref, items$, filters?: Array<Array<any>>, orderBy?: string) {
    let query = null
    if (filters.length > 0) {
      filters.forEach((_filter) => {
        console.log('where =>>', _filter[0], _filter[1], _filter[2])
        query = this.db(ref).where(_filter[0], _filter[1], _filter[2])
      })
    } else {
      query = this.db(ref)
    }
    if (!orderBy)
      return query.onSnapshot(snap => {
        items$.next(snap.docs.map(_docs => { return this.decorate({ ..._docs.data(), id: _docs.id }) }))
      })
    else
      return query.orderBy(orderBy, 'asc').onSnapshot(snap => {
        items$.next(snap.docs.map(_docs => { return this.decorate({ ..._docs.data(), id: _docs.id }) }))
      })
  }

  decorate(item) {
    if (item.created_at) {
      item.$created_at = item.created_at.toDate ? item.created_at.toDate() : item.created_at

      const year = item.$created_at.getFullYear()
      const monthForNumbers = item.$created_at.getMonth() + 1
      const dd = item.$created_at.getDate()
      
      item.$created_at = dd + '-' + monthForNumbers + '-' + year
      
    }
    return item
  }

  getGroup(ref, filters?, orderBy?) {
    let query = this.group(ref)
    if (filters.length > 0) {
      filters.forEach((_filter) => {
        query = query.where(_filter[0], _filter[1], _filter[2])
      })
    } else {
      query = this.group(ref)
    }
    return query.orderBy(orderBy || 'created_at', 'desc').get();
  }

  deleteFileStorage(ref: string) {
    return firebase.storage().ref(ref).delete()
  }

  onCallFunctions(name, data) {
    if (name === 'requestCensor') {
      const request = firebase.app().functions('europe-west2').httpsCallable(name)(data)
      return request
    } else {
      const request = firebase.functions().httpsCallable(name)(data)
      return request
    }
  }

  async getUserByEmail(email) {
    const ref = '/users'
    const snap = await this.getCollection(ref, [['email', '==', email]] || [])
    return snap.docs.map(doc => doc.data())
  }
}
