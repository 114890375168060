import { AuthService } from './../../services/auth.service';
import { UsersService } from './../../services/users.service';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit, OnDestroy {
  user = null
  signInOut = false;
  signUpOut = false;
  userMenuOut = false;
  constructor(
    public usersService:UsersService ,
    private zone:NgZone,
    private authService:AuthService,
    private router:Router
  ) { }


  toggle(type:'sign-in' | 'sign-up' | 'user-menu'){
    if (type == 'sign-in') {
      this.signInOut = this.signInOut === true ? false : true
    }
    if (type == 'sign-up') {
      this.signUpOut = this.signUpOut === true ? false : true
    }
    if (type == 'user-menu') {
      this.userMenuOut = this.userMenuOut === true ? false : true
    }
  }

  handleEvent(e){
    if(e.cancel){
      this.signInOut = false;
    }
  }

  async signOut(){
   this.router.navigate([''])
   this.usersService.user = null;
   this.authService.user = null;
   this.usersService.user$.next(null)

   await this.authService.signOut()
  }

  userSub:Subscription = null
  ngOnInit() {
   this.userSub = this.usersService.userFirebase$.subscribe((_user)=>{
      this.zone.run(()=>{
        this.user = _user
        this.signInOut = false;
        this.signUpOut = false;
        this.userMenuOut = false;
      })
    })
  }
  ngOnDestroy(){
    this.userSub.unsubscribe();
  }
}
